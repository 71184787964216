var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tx-table__cell tx-table__cell--align-right",staticStyle:{"padding-left":"0"}},[([
            'jetton:burn',
            'jetton:burn_notification',
            'jetton:transfer',
            'jetton:transfer_notification',
            'jetton:internal_transfer',
        ].includes(_vm.action.type))?[_c('div',{staticStyle:{"margin-right":"2px"}},[(!_vm.is_out)?_c('span',[_vm._v("+")]):_c('span',[_vm._v("−")])]),_vm._v(" "),_c('ui-inline-jetton',_vm._b({attrs:{"address":_vm.meta.jetton_address,"value":_vm.action.amount}},'ui-inline-jetton',_vm.meta.jetton,false))]:(_vm.action.type === 'nft:ownership_assigned')?_c('ui-inline-nft-item',{attrs:{"address":_vm.from}}):(_vm.action.type === 'nft:transfer')?_c('ui-inline-nft-item',{attrs:{"address":_vm.to}}):(_vm.action.type.startsWith('pool:'))?[(_vm.action.type === 'pool:new_stake_ok')?[_c('span',{staticClass:"tx-row-msg-action tx-row-msg-action--single"},[_vm._v("\n                    Stake accepted\n                ")])]:[_vm._v("\n                "+_vm._s(_vm.$ton(_vm.amount, undefined, true))+" ICE\n            ")]]:[_vm._v("\n            "+_vm._s(_vm.$ton(_vm.amount))+" ICE\n        ")]],2),_vm._v(" "),_c('div',{staticClass:"tx-table__cell tx-table__cell--align-right muted",staticStyle:{"font-size":"12px","padding-left":"0"}},[(_vm.action.type.endsWith(':excesses'))?_c('span',[_vm._v("\n            Excess\n        ")]):(_vm.action.type.startsWith('pool:'))?[(_vm.action.type === 'pool:recover_stake')?[_vm._v("\n                Recover stake\n            ")]:(_vm.action.type === 'pool:recover_stake_ok')?[_vm._v("\n                Stake recovered\n            ")]:(_vm.action.type === 'pool:new_stake')?[(_vm.amount > 5000000000)?[_vm._v("\n                    Add stake\n                ")]:[_vm._v("\n                    Push stake\n                ")]]:(_vm.action.type === 'pool:update_validator_set_hash')?[_vm._v("\n                Update validator\n            ")]:(_vm.action.type === 'pool:process_withdraw_requests')?[_vm._v("\n                Process withdraws\n            ")]:_vm._e()]:([
            'jetton:burn',
            'jetton:burn_notification',
            'jetton:transfer',
            'jetton:transfer_notification',
            'jetton:internal_transfer',
        ].includes(_vm.action.type))?[(_vm.action.type === 'jetton:burn' || _vm.action.type === 'jetton:burn_notification')?_c('span',[_vm._v("\n                Burn\n            ")]):[(_vm.is_out)?_c('span',[_vm._v("\n                    Transferred\n                ")]):_c('span',[_vm._v("\n                    Received\n                ")])]]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }